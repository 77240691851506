import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import ImageMeta from "../ImageMeta"
import NuvoImage from "../NuvoImage"
import { getMonthYearFromStrings } from "../../utils/utils"

import ImageCaption from "./ImageCaption"

var classNames = require("classnames")

function FacewallImage(props) {
  let nameClasses = classNames("image-caption staff fixed-facewall-name", {
    last: !props.procedure && !props.monthYear
  })
  let procedureClasses = classNames(
    "image-caption staff fixed-facewall-procedure",
    {
      last: !props.monthYear
    }
  )
  let timeClasses = classNames("image-caption staff fixed-facewall-time", {
    last: true
  })

  let url = props.url
  if (url.charAt(0) !== "/") url = "/" + url

  return (
    <>
      <div className="column is-6 fixed-facewall-item has-text-centered">
        <div className={props.className}>
          <Link title="View the full testimonial" to={url.toLowerCase()}>
            <NuvoImage
              useAR
              cloudName="nuvolum"
              publicId={props.imageUrl}
              data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto:best/v1/${props.imageUrl}`}
              width="auto"
              responsive
              responsiveUseBreakpoints="true"
            ></NuvoImage>
          </Link>
          {(props.reviewerName || props.procedure || props.monthYear) && (
            <Link
              title="View the full testimonial"
              className="image-caption-link"
              to={url.toLowerCase()}
            >
              <ImageCaption
                reviewerName={props.reviewerName}
                language={props.language}
                procedure={props.procedure}
                monthYear={props.monthYear}
                hideProcedureNames={props.hideProcedureNames}
              />
            </Link>
          )}
        </div>
      </div>
    </>
  )
}

FacewallImage.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  procedure: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  reviewerName: PropTypes.string,
  language: PropTypes.string,
  monthYear: PropTypes.object
}

export default FacewallImage
